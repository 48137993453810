:root {
  --sans-font: -apple-system, BlinkMacSystemFont, "Avenir Next", Avenir, "Nimbus Sans L", Roboto, "Noto Sans", "Segoe UI", Arial, Helvetica, "Helvetica Neue", sans-serif;
  --mono-font: Consolas, Menlo, Monaco, "Andale Mono", "Ubuntu Mono", monospace;
  --bg: #fff;
  --accent-bg: #f5f7ff;
  --text: #212121;
  --text-light: #585858;
  --border: #898ea4;
  --accent: #0d47a1;
  --code: #d81b60;
  --preformatted: #444;
  --marked: #fd3;
  --disabled: #efefef;
}

@media (prefers-color-scheme: dark) {
  :root {
    color-scheme: dark;
    --bg: #212121;
    --accent-bg: #2b2b2b;
    --text: #dcdcdc;
    --text-light: #ababab;
    --accent: #ffb300;
    --code: #f06292;
    --preformatted: #ccc;
    --disabled: #111;
  }

  img, video {
    opacity: .8;
  }
}

*, :before, :after {
  box-sizing: border-box;
}

textarea, select, input, progress {
  appearance: none;
}

html {
  font-family: var(--sans-font);
  scroll-behavior: smooth;
}

body {
  color: var(--text);
  background-color: var(--bg);
  grid-template-columns: 1fr min(45rem, 90%) 1fr;
  margin: 0;
  font-size: 1.15rem;
  line-height: 1.5;
  display: grid;
}

body > * {
  grid-column: 2;
}

body > header {
  background-color: var(--accent-bg);
  border-bottom: 1px solid var(--border);
  text-align: center;
  grid-column: 1 / -1;
  padding: 0 .5rem 2rem;
}

body > header h1 {
  max-width: 1200px;
  margin: 1rem auto;
}

body > header p {
  max-width: 40rem;
  margin: 1rem auto;
}

main {
  padding-top: 1.5rem;
}

body > footer {
  color: var(--text-light);
  text-align: center;
  border-top: 1px solid var(--border);
  margin-top: 4rem;
  padding: 2rem 1rem 1.5rem;
  font-size: .9rem;
}

h1 {
  font-size: 3rem;
}

h2 {
  margin-top: 3rem;
  font-size: 2.6rem;
}

h3 {
  margin-top: 3rem;
  font-size: 2rem;
}

h4 {
  font-size: 1.44rem;
}

h5 {
  font-size: 1.15rem;
}

h6 {
  font-size: .96rem;
}

p, h1, h2, h3, h4, h5, h6 {
  overflow-wrap: break-word;
}

h1, h2, h3 {
  line-height: 1.1;
}

@media only screen and (max-width: 720px) {
  h1 {
    font-size: 2.5rem;
  }

  h2 {
    font-size: 2.1rem;
  }

  h3 {
    font-size: 1.75rem;
  }

  h4 {
    font-size: 1.25rem;
  }
}

a, a:visited {
  color: var(--accent);
}

a:hover {
  text-decoration: none;
}

button, [role="button"], input[type="submit"], input[type="reset"], input[type="button"], label[type="button"] {
  background-color: var(--accent);
  color: var(--bg);
  border: none;
  border-radius: 5px;
  margin: .5rem 0;
  padding: .7rem .9rem;
  font-size: 1rem;
}

button[disabled], [role="button"][aria-disabled="true"], input[type="submit"][disabled], input[type="reset"][disabled], input[type="button"][disabled], input[type="checkbox"][disabled], input[type="radio"][disabled], select[disabled] {
  opacity: .5;
  cursor: not-allowed;
}

input:disabled, textarea:disabled, select:disabled {
  cursor: not-allowed;
  background-color: var(--disabled);
}

input[type="range"] {
  padding: 0;
}

abbr[title] {
  cursor: help;
  text-decoration-line: underline;
  text-decoration-style: dotted;
}

button:focus, button:enabled:hover, [role="button"]:focus, [role="button"]:not([aria-disabled="true"]):hover, input[type="submit"]:focus, input[type="submit"]:enabled:hover, input[type="reset"]:focus, input[type="reset"]:enabled:hover, input[type="button"]:focus, input[type="button"]:enabled:hover, label[type="button"]:focus, label[type="button"]:hover {
  filter: brightness(1.4);
  cursor: pointer;
}

header > nav {
  padding: 1rem 0 0;
  font-size: 1rem;
  line-height: 2;
}

header > nav ul, header > nav ol {
  flex-flow: wrap;
  place-content: space-around center;
  align-items: center;
  margin: 0;
  padding: 0;
  list-style-type: none;
  display: flex;
}

header > nav ul li, header > nav ol li {
  display: inline-block;
}

header > nav a, header > nav a:visited {
  border: 1px solid var(--border);
  color: var(--text);
  border-radius: 5px;
  margin: 0 .5rem 1rem;
  padding: .1rem 1rem;
  text-decoration: none;
  display: inline-block;
}

header > nav a:hover {
  border-color: var(--accent);
  color: var(--accent);
  cursor: pointer;
}

@media only screen and (max-width: 720px) {
  header > nav a {
    border: none;
    padding: 0;
    line-height: 1;
    text-decoration: underline;
  }
}

aside, details, pre, progress {
  background-color: var(--accent-bg);
  border: 1px solid var(--border);
  border-radius: 5px;
  margin-bottom: 1rem;
}

aside {
  width: 30%;
  float: right;
  margin-left: 15px;
  padding: 0 15px;
  font-size: 1rem;
}

@media only screen and (max-width: 720px) {
  aside {
    width: 100%;
    float: none;
    margin-left: 0;
  }
}

article, fieldset {
  border: 1px solid var(--border);
  border-radius: 5px;
  margin-bottom: 1rem;
  padding: 1rem;
}

article h2:first-child, section h2:first-child {
  margin-top: 1rem;
}

section {
  border-top: 1px solid var(--border);
  border-bottom: 1px solid var(--border);
  margin: 3rem 0;
  padding: 2rem 1rem;
}

section + section, section:first-child {
  border-top: 0;
  padding-top: 0;
}

section:last-child {
  border-bottom: 0;
  padding-bottom: 0;
}

details {
  padding: .7rem 1rem;
}

summary {
  cursor: pointer;
  word-break: break-all;
  margin: -.7rem -1rem;
  padding: .7rem 1rem;
  font-weight: bold;
}

details[open] > summary + * {
  margin-top: 0;
}

details[open] > summary {
  margin-bottom: .5rem;
}

details[open] > :last-child {
  margin-bottom: 0;
}

table {
  border-collapse: collapse;
  width: 100%;
  margin: 1.5rem 0;
  display: block;
  overflow: auto;
}

td, th {
  border: 1px solid var(--border);
  text-align: left;
  padding: .5rem;
}

th {
  background-color: var(--accent-bg);
  font-weight: bold;
}

tr:nth-child(2n) {
  background-color: var(--accent-bg);
}

table caption {
  margin-bottom: .5rem;
  font-weight: bold;
}

textarea, select, input {
  font-size: inherit;
  color: var(--text);
  background-color: var(--bg);
  border: 1px solid var(--border);
  box-shadow: none;
  max-width: 100%;
  border-radius: 5px;
  margin-bottom: .5rem;
  padding: .5rem;
  font-family: inherit;
  display: inline-block;
}

label {
  display: block;
}

textarea:not([cols]) {
  width: 100%;
}

select:not([multiple]) {
  background-image: linear-gradient(45deg, transparent 49%, var(--text) 51%), linear-gradient(135deg, var(--text) 51%, transparent 49%);
  background-position: calc(100% - 15px), calc(100% - 10px);
  background-repeat: no-repeat;
  background-size: 5px 5px, 5px 5px;
  padding-right: 25px;
}

input[type="checkbox"], input[type="radio"] {
  vertical-align: middle;
  width: min-content;
  position: relative;
}

input[type="checkbox"] + label, input[type="radio"] + label {
  display: inline-block;
}

input[type="radio"] {
  border-radius: 100%;
}

input[type="checkbox"]:checked, input[type="radio"]:checked {
  background-color: var(--accent);
}

input[type="checkbox"]:checked:after {
  content: " ";
  width: .18em;
  height: .32em;
  border-right: solid var(--bg) .08em;
  border-bottom: solid var(--bg) .08em;
  background-color: #0000;
  border-radius: 0;
  font-size: 1.8em;
  position: absolute;
  top: .05em;
  left: .17em;
  transform: rotate(45deg);
}

input[type="radio"]:checked:after {
  content: " ";
  width: .25em;
  height: .25em;
  background-color: var(--bg);
  border-radius: 100%;
  font-size: 32px;
  position: absolute;
  top: .125em;
  left: .125em;
}

@media only screen and (max-width: 720px) {
  textarea, select, input {
    width: 100%;
  }
}

input[type="color"] {
  height: 2.5rem;
  padding: .2rem;
}

input[type="file"] {
  border: 0;
}

hr {
  height: 1px;
  background: var(--border);
  border: none;
  margin: 1rem auto;
}

mark {
  background-color: var(--marked);
  border-radius: 4px;
  padding: 2px 5px;
}

img, video {
  max-width: 100%;
  height: auto;
  border-radius: 5px;
}

figure {
  text-align: center;
  margin: 0;
}

figcaption {
  color: var(--text-light);
  margin-bottom: 1rem;
  font-size: .9rem;
}

blockquote {
  border-left: .35rem solid var(--accent);
  color: var(--text-light);
  margin: 2rem 0 2rem 2rem;
  padding: .4rem .8rem;
  font-style: italic;
}

cite {
  color: var(--text-light);
  font-size: .9rem;
  font-style: normal;
}

dt {
  color: var(--text-light);
}

code, pre, pre span, kbd, samp {
  font-family: var(--mono-font);
  color: var(--code);
}

kbd {
  color: var(--preformatted);
  border: 1px solid var(--preformatted);
  border-bottom: 3px solid var(--preformatted);
  border-radius: 5px;
  padding: .1rem .4rem;
}

pre {
  max-width: 100%;
  color: var(--preformatted);
  padding: 1rem 1.4rem;
  overflow: auto;
}

pre code {
  color: var(--preformatted);
  background: none;
  margin: 0;
  padding: 0;
}

progress {
  width: 100%;
}

progress:indeterminate {
  background-color: var(--accent-bg);
}

progress::-webkit-progress-bar {
  background-color: var(--accent-bg);
  border-radius: 5px;
}

progress::-webkit-progress-value {
  background-color: var(--accent);
  border-radius: 5px;
}

progress::-moz-progress-bar {
  background-color: var(--accent);
  border-radius: 5px;
  transition-property: width;
  transition-duration: .3s;
}

progress:indeterminate::-moz-progress-bar {
  background-color: var(--accent-bg);
}

/*# sourceMappingURL=index.a4c74444.css.map */
